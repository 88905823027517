.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.disabled-overlay {
	width: 100%;
	height: 100%;
	opacity: 0.5;
	transition: all 0.2s;
	background: rgba(0, 0, 0, 0.2);
	z-index: 9999;
	pointer-events: none;
}

/* ############# Layout changes ##################### */

table {
	border-spacing: 2px !important;
}

.MuiTooltip-tooltip span {
	font-size: 0.9rem !important;
	line-height: 1.3rem;
	font-weight: normal;
}

.MuiTableCell-sizeSmall {
	padding: 6px 4px !important;
}
.Layout-content-4 {
	margin-top: 1rem;
	margin-bottom: 4rem;
}

.MuiToolbar-root {
	flex-wrap: wrap;
}

.filtersRow {
	margin: 0;
}

.break {
	flex-basis: 100%;
	height: 0;
}

.fullWidth {
	width: 100%;
}

td span {
	font-size: 0.75rem;
}

.tableEdit {
	max-width: 60px;
	float: right;
}

.maxWidthEdit960,
.maxWidth960 {
	max-width: 960px;
}

.sidePanelFix > div {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
}

.sidePanelFix > div > div {
	margin: 0 0.5em;
	flex-direction: column;
	flex-basis: 100%;
}

.thinLeftPanel > div > div:first-child {
	max-width: 400px;
}

.custServiceBottomButtons {
	margin-top: 3em;
	padding: 1.5em;
	background: #ddd;
	border-top: 1px solid #777;
}

.custServiceBottomButtons > div {
	margin: 1em 1.5em 1em 0;
	display: inline-block;
}

.spacingShrinked {
	letter-spacing: -1px !important;
}

.condensed {
	letter-spacing: -0.5px !important;
	font-size: 0.625rem !important;
}

.trimmedText {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	resize: horizontal;
	display: inline-block;
	width: 160px;
}

.trimmedText.width50 {
	width: 50px;
}

.trimmedText.width60 {
	width: 60px;
}
.trimmedText.width70 {
	width: 70px;
}
.trimmedText.width80 {
	width: 80px;
}

@media (min-width: 1023px) {
	.sidePanelFix > div > div {
		flex: 1;
	}

	.MuiTab-root {
		min-width: 90px !important;
	}
}

.cpaAdvertiserForm div[role=toolbar] div:first-child {
	max-width: 250px;
}
    